import { 
  ConnectionStatus,
  NodeConfig,
  FlowState,
  Message,
  NodeFunction
} from './types';
import type { ChatMessage } from './types';
import { EventEmitter } from 'events';

interface FlowManagerConfig {
  persistConversation?: (
    conversationId: string,
    messages: Message[],
    status: ConnectionStatus
  ) => Promise<void>;
  webrtcManager?: EventEmitter;
}

export class FlowManager extends EventEmitter {
  private nodes: Map<string, NodeConfig> = new Map();
  private currentState: FlowState;
  private subscribers: Array<(state: FlowState) => void> = [];
  private functionHandlers = new Map<string, NodeFunction['handler']>();

  constructor(private config?: FlowManagerConfig) {
    super();
    this.currentState = {
      status: 'disconnected',
      messages: [],
      conversationId: undefined,
      currentNode: '',
      customState: {},
      dataChannelReady: false
    };
  }

  updateConversationId(id: string) {
    this.currentState.conversationId = id;
    this.notify();
  }

  async completeConversation() {
    if (this.config?.persistConversation) {
      await this.config.persistConversation(
        this.currentState.conversationId || '',
        this.currentState.messages,
        this.currentState.status === 'connected' ? 'connected' : 'disconnected'
      );
    }
  }

  addMessage(message: Omit<Message, 'timestamp'>) {
    const fullMessage: Message = {
      ...message,
      timestamp: new Date().toISOString()
    };
    
    this.currentState.messages.push(fullMessage);
    this.notify();
  }

  updateStatus(newStatus: ConnectionStatus) {
    console.log('[Flow] Updating status from', 
      this.currentState.status, 'to', newStatus);
    if (this.currentState.status !== newStatus) {
      console.log('[Flow] Status update:', newStatus);
      this.currentState.status = newStatus;
      this.notify();
    }
  }

  subscribe(callback: (state: FlowState) => void): () => void {
    callback(this.currentState);
    this.subscribers.push(callback);
    return () => {
      this.subscribers = this.subscribers.filter(cb => cb !== callback);
    };
  }

  registerNode(config: NodeConfig): void {
    if (this.nodes.has(config.id)) {
      console.warn(`Overwriting existing node: ${config.id}`);
    }
    this.nodes.set(config.id, config);

    config.functions?.forEach(func => {
      if (func.handler) {
        console.log(`[Flow] Registered function: ${func.name}`);
        this.functionHandlers.set(func.name, func.handler);
      }
    });
  }

  async transitionTo(nodeId: string): Promise<void> {
    if (this.currentState.currentNode === nodeId) {
      return;
    }

    if (!this.currentState.dataChannelReady) {
      console.log('[Flow] Queuing transition to', nodeId);
      return new Promise((resolve) => {
        const unsubscribe = this.subscribe((state) => {
          if (state.dataChannelReady) {
            unsubscribe();
            this.transitionTo(nodeId).then(resolve);
          }
        });
      });
    }

    if (nodeId === '') {
      this.currentState.currentNode = '';
      this.notify();
      return;
    }

    if (!this.nodes.has(nodeId)) {
      console.error(`Invalid node transition: ${nodeId}`);
      return;
    }

    const previousNode = this.currentState.currentNode;
    const currentNodeConfig = this.nodes.get(previousNode);
    
    if (currentNodeConfig?.transitions) {
      console.log(`Valid transitions from ${previousNode}:`, 
        Object.values(currentNodeConfig.transitions));
    }

    this.currentState.currentNode = nodeId;
    this.currentState.customState = {};
    
    const node = this.nodes.get(nodeId);
    if(node) {
      console.log(`[Flow] Transition: ${previousNode} → ${nodeId}`);
      this.notify();
      this.emit('nodeTransition', node);
    }
  }

  getCurrentNode(): NodeConfig | undefined {
    return this.nodes.get(this.currentState.currentNode);
  }

  private notify() {
    console.log('[Flow] Notifying', this.subscribers.length, 'subscribers');
    this.subscribers.forEach(cb => cb(this.currentState));
  }

  getLLMContext(): ChatMessage[] {
    const node = this.nodes.get(this.currentState.currentNode);
    if (!node) return [];

    return [
      ...(node.roleMessages || []),
      ...(node.taskMessages || []),
      ...this.currentState.messages.map(msg => ({
        role: msg.role,
        content: msg.content
      }))
    ];
  }

  getState(): FlowState {
    return this.currentState;
  }

  updateDataChannelReady(ready: boolean) {
    this.currentState.dataChannelReady = ready;
    this.notify();
  }

  async handleFunctionCall(name: string, args: any, callId?: string) {
    const handler = this.functionHandlers.get(name);
    
    if (!handler) {
      console.error(`[Flow] No handler for function: ${name}`);
      return;
    }

    try {
      const result = await handler(args);
      
      if (result?.transitionTo) {
        this.transitionTo(result.transitionTo);
      }
      
      return result;
    } catch (error) {
      console.error(`[Flow] Function ${name} failed:`, error);
      this.updateStatus('error');
      throw error;
    }
  }

  resetState(): void {
    this.currentState = {
      status: 'disconnected',
      messages: [],
      conversationId: undefined,
      currentNode: '',
      customState: {},
      dataChannelReady: false
    };
    this.notify();
  }

  unregisterNode(nodeId: string): void {
    // Add actual node cleanup logic here
    // Example implementation:
    if (this.nodes.has(nodeId)) {
      this.nodes.delete(nodeId);
      console.log(`Unregistered node: ${nodeId}`);
    }
  }
}

export const flowManager = new FlowManager(); 