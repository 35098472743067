import { useEffect, useRef } from 'react';
import { WebRTCManager } from './WebRTCManager';
import { flowManager } from './FlowManager';
import { EventBridge } from './EventBridge';
import { ConnectionStatus } from './types';

export function useWebRTCAdapter(props: {
  getEphemeralKey: () => Promise<string>;
}) {
  const webRTCRef = useRef<WebRTCManager>();

  useEffect(() => {
    console.log('[Adapter] Initializing WebRTC connection');
    const webRTC = new WebRTCManager({
      getEphemeralKey: async () => {
        const key = await props.getEphemeralKey();
        return key;
      }
    });

    webRTCRef.current = webRTC;

    // Use global flowManager
    new EventBridge(webRTC, flowManager);

    webRTC.on('connectionstatechange', (state: ConnectionStatus) => {
      console.log('[WebRTC] State change → FlowManager:', state);
      flowManager.updateStatus(state);
    });

    const handleConnect = async () => {
      try {
        await webRTC.connect();
      } catch (error) {
        console.error('Connection failed:', error);
      }
    };

    handleConnect();

    return () => {
      webRTC.disconnect();
    };
  }, []);

  return {
    cleanup: async () => {
      await webRTCRef.current?.disconnect();
    }
  };
} 