import React, { useMemo, useEffect, useState } from 'react';
import { useWebRTCAdapter } from '../realtime/useWebRTCAdapter';
import { DocumentDto } from '../data/interfaces/document';
import './AIChatSidebar.css';
import axiosInstance from '../utils/axiosInstance';
import { Message } from '../realtime/types';
import { flowManager } from '../realtime';
import { ConnectionStatus } from '../realtime/types';
import { FlowState } from '../realtime/types';
import { JSONSchema7 } from 'json-schema';

interface AIChatSidebarProps {
  onClose: () => void;
  document?: DocumentDto;
}

export const AIChatSidebar: React.FC<AIChatSidebarProps> = ({ onClose, document }) => {
  const [flowState, setFlowState] = useState(flowManager.getState());
  const { cleanup } = useWebRTCAdapter({
    getEphemeralKey: async () => {
      const response = await axiosInstance.post('/api/generate-ephemeral-key');
      return response.data.ephemeral_key;
    }
  });

  const statusDescriptions: Record<ConnectionStatus, string> = {
    disconnected: 'Initializing...',
    connecting: 'Connecting to AI...',
    connected: 'Connected - Ready...',
    listening: 'Listening...',
    processing: 'Processing...',
    error: 'Connection failed. Please try again.'
  };

  const statusText = statusDescriptions[flowState.status] || 'Unknown status';

  useEffect(() => {
    const unsubscribe = flowManager.subscribe((newState) => {
      // Force new object reference
      setFlowState({ ...newState });
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    console.log('[Sidebar] Flow state changed:', flowState);
  }, [flowState]);


  // Use flowState.status and flowState.messages
  
  const generateInstructions = (doc?: DocumentDto): string => {
    if (!doc) return "You are a helpful Chinese language teacher";
    
    return `You are a Chinese language tutor. The current text we are studying is
      titled "${doc.title}". Here is the text:

      <TEXT>${doc.text}</TEXT>

      ${doc.json_structure?.text_analysis ? `
      <TEXT_ANALYSIS>
        Key Vocabulary:
        ${doc.json_structure.text_analysis.words.map(word => `
          - ${word.text} (${word.pinyin}): ${word.meaning}
        `).join('')}

        Key Phrases:
        ${doc.json_structure.text_analysis.phrases.map(phrase => `
          - ${phrase.text} (${phrase.pinyin}): ${phrase.meaning}
        `).join('')}

        Translation Pairs:
        ${doc.json_structure.text_analysis.pairs.map(pair => `
          - ${pair.text}: ${pair.meaning}
        `).join('')}
      </TEXT_ANALYSIS>
      ` : ''}

      Use this analysis to help the student by:
      - Referencing pre-analyzed vocabulary
      - Building on existing translations
      - Expanding usage contexts`;
  };

  const handleClose = () => {
    cleanup();
    flowManager.resetState();
    onClose();
  };

  // Create node configuration
  const lessonStartNode = useMemo(() => ({
    id: 'lesson_start',
    roleMessages: [{
      role: 'system' as const,
      content: 'You are a Chinese language tutor'
    }],
    taskMessages: [{
      role: 'system' as const,
      content: generateInstructions(document)
    }],
    functions: [{
      name: "get_current_time",
      description: "Get current local time",
      parameters: {
        type: "object",
        properties: {},
        required: []
      } as JSONSchema7,
      handler: async () => {
        const time = new Date().toLocaleTimeString();
        console.log('[Time] Current time:', time);
        return { message: `Current time is ${time}` };
      }
    }, {
      name: "transition_to_lesson_end",
      description: "End the current lesson session",
      parameters: {
        type: "object",
        properties: {
          completion_status: { 
            type: "string",
            enum: ["completed", "paused", "early_exit"]
          }
        },
        required: ["completion_status"]
      } as JSONSchema7,
      handler: async (args: any) => {
        console.log('[Flow] Transitioning to lesson end');
        await flowManager.transitionTo('lesson_end');
        return { status: "success" };
      }
    }]
  }), [document]);

  // Add new lesson_end node configuration
  const lessonEndNode = useMemo(() => ({
    id: 'lesson_end',
    roleMessages: [{
      role: 'system' as const,
      content: 'You are a Chinese language tutor wrapping up the session'
    }],
    taskMessages: [{
      role: 'system' as const,
      content: 'Provide a summary of key points.'
    }],
    functions: []
  }), []);

  // Update useEffect to handle both nodes
  useEffect(() => {
    flowManager.registerNode(lessonStartNode);
    flowManager.registerNode(lessonEndNode);

    const handleStateChange = (state: FlowState) => {
      if(state.status === 'connected' && state.currentNode !== 'lesson_start') {
        console.log('[Sidebar] Connected, forcing transition');
        flowManager.transitionTo('lesson_start');
      }
    };

    handleStateChange(flowManager.getState());
    const unsubscribe = flowManager.subscribe(handleStateChange);

    return () => {
      unsubscribe();
      flowManager.unregisterNode(lessonStartNode.id);
      flowManager.unregisterNode(lessonEndNode.id);
    };
  }, [lessonStartNode, lessonEndNode]); // Add lessonEndNode to dependencies

  return (
    <>
      <div className="ai-sidebar-overlay" onClick={handleClose} />
      <div className="ai-sidebar">
        <div className={`status-indicator ${flowState.status}`}>
          {statusText}
        </div>
        
        <div className="messages-container">
          {flowState.messages.map((message: Message, index: number) => (
            <div key={index} className={`message ${message.role}`}>
              {message.content}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}; 