import type { WebRTCManager } from './WebRTCManager';
import type { FlowManager } from './FlowManager';
import type { FlowState, SessionUpdate } from './types';

export class EventBridge {
  private previousNode: string = '';

  constructor(
    private webRTC: WebRTCManager,
    private flow: FlowManager
  ) {
    this.setupListeners();
    this.flow.subscribe(this.handleFlowUpdate);
    
    // Bind the handler to maintain 'this' context
    this.handleServerEvent = this.handleServerEvent.bind(this);
  }

  private setupListeners() {
    this.webRTC.on('message', (event: any) => {
      switch (event.type) {
        case 'conversation.item.input_audio_transcription.completed':
          this.flow.addMessage({
            role: 'user',
            content: event.transcript?.trim() || ''
          });
          break;
          
        case 'response.audio_transcript.done':
          this.flow.addMessage({
            role: 'assistant',
            content: event.transcript?.trim() || ''
          });
          break;

        case 'input_audio_buffer.speech_started':
          this.flow.updateStatus('listening');
          break;

        case 'input_audio_buffer.speech_stopped':
          this.flow.updateStatus('processing');
          break;
      }
    });

    this.webRTC.on('error', (error: Error) => {
      this.handleError(error);
    });

    this.webRTC.on('response.done', this.handleServerEvent);

    this.webRTC.on('connected', () => {
      console.log('[EventBridge] Connected');
    });

    this.webRTC.on('datachannelready', (ready: boolean) => {
      this.flow.updateDataChannelReady(ready);
    });
  }

  private handleServerEvent = (event: any) => {
    console.log('[EventBridge] Received event:', event.type);
    
    if (event.type === 'response.done') {
      const functionCall = event.response?.output?.[0];
      
      if (functionCall?.type === 'function_call') {
        try {
          this.flow.handleFunctionCall(
            functionCall.name,
            JSON.parse(functionCall.arguments),
            functionCall.call_id
          );
        } catch (error) {
          console.error('Function call failed:', error);
        }
      }
    }
  }

  private updateSessionContext() {
    const node = this.flow.getCurrentNode();
    const currentSession = this.flow.getState().session; // Get existing session config

    const sessionUpdate: SessionUpdate = {
      session: {
        ...currentSession, // Preserve existing properties
        instructions: this.getSystemMessages(),
        tools: node?.functions?.map(fn => ({
          type: 'function' as const,
          name: fn.name,
          description: fn.description,
          parameters: fn.parameters
        })),
        tool_choice: node?.functions?.length ? "auto" : undefined
      }
    };

    this.webRTC.sendSessionUpdate(sessionUpdate);
  }

  private handleFlowUpdate = (state: FlowState) => {
    if(state.currentNode !== this.previousNode) {
      console.log(`[EventBridge] Entering node: ${state.currentNode}`);
      this.previousNode = state.currentNode;
      this.updateSessionContext();
    }
  };

  private getSystemMessages(): string {
    const node = this.flow.getCurrentNode();
    return [
      ...(node?.roleMessages || []),
      ...(node?.taskMessages || [])
    ]
    .filter(m => m.role === 'system')
    .map(m => m.content)
    .join('\n');
  }

  private handleError(error: Error) {
    console.error('[EventBridge] Error:', error.message, error.stack);
    this.flow.updateStatus('error');
  }
} 