import axios from 'axios';
import { WebRTCConfig, ConnectionStatus, SessionUpdate } from './types';

export class WebRTCManager {
  private pc: RTCPeerConnection | null = null;
  private dc: RTCDataChannel | null = null;
  private config: WebRTCConfig;

  private defaultSessionConfig: SessionUpdate = {
    session: {
      modalities: ["text", "audio"],
      instructions: " ",
      input_audio_format: "pcm16",
      output_audio_format: "pcm16",
      input_audio_transcription: {
        model: "whisper-1"
      },
      turn_detection: {
        type: "server_vad",
        threshold: 0.5,
        prefix_padding_ms: 300,
        silence_duration_ms: 1000,
        create_response: true
      },
      temperature: 0.8,
      max_response_output_tokens: 4096
    }
  };

  constructor(config: WebRTCConfig) {
    this.config = {
      ...config,
    };
  }

  async connect() {
    console.log('[WebRTC] Starting connection process');
    try {
      this.pc = new RTCPeerConnection();
      console.log('[WebRTC] PeerConnection created');
      
      // Audio tracks
      const inputStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log('[WebRTC] Got user media with', inputStream.getAudioTracks().length, 'tracks');
      this.pc.addTrack(inputStream.getAudioTracks()[0]);
      
      this.pc.ontrack = (event) => {
        console.log('[WebRTC] Received remote track', event.track.kind);
        const outputStream = event.streams[0];
        const audioElement = new Audio();
        audioElement.srcObject = outputStream;
        audioElement.play();
      };

      this.setupDataChannel();
      console.log('[WebRTC] Data channel setup complete');
      
      let timeoutHandle: NodeJS.Timeout;
      const timeoutPromise = new Promise((_, reject) => {
        timeoutHandle = setTimeout(() => {
          console.error('[WebRTC] Connection timeout after 15s');
          reject(new Error('Connection timeout'));
        }, 15000);
      });

      console.log('[WebRTC] Starting offer/answer exchange');
      await Promise.race([this.handleOfferAnswer(), timeoutPromise]);
      clearTimeout(timeoutHandle!);
      
      console.log('[WebRTC] Connection established, setting up listeners');
      this.setupEventListeners();

    } catch (error) {
      console.error('[WebRTC] Connection failed:', error);
      this.disconnect();
      throw error;
    }
  }

  private setupDataChannel() {
    this.dc = this.pc!.createDataChannel('oai-events');
    
    this.dc.onopen = () => {
      this.emit('datachannelopen');
      this.sendSessionUpdate(this.defaultSessionConfig);
      this.emit('datachannelready', true);
    };

    this.dc.onclose = () => {
      this.emit('datachannelready', false);
    };

    this.dc.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        this.emit(data.type, data);
        this.emit('message', data);
      } catch (error) {
        console.error('Failed to parse message:', error);
      }
    };

    this.dc.onerror = (error) => {
      this.emit('error', error);
    };
  }

  private async handleOfferAnswer() {
    console.log('[WebRTC] Creating offer');
    const offer = await this.pc!.createOffer();
    console.log('[WebRTC] Offer created:', offer.type);
    
    await this.pc!.setLocalDescription(offer);
    console.log('[WebRTC] Local description set');

    const ephemeralKey = await this.config.getEphemeralKey();
    console.log('[WebRTC] Using ephemeral key:', ephemeralKey.slice(0, 6), '...');
    
    const sdpResponse = await this.sendSDPMessage(offer.sdp!, ephemeralKey);
    console.log('[WebRTC] SDP response received:', sdpResponse.status);

    const answer: RTCSessionDescriptionInit = {
      type: "answer",
      sdp: await sdpResponse.data
    };
    console.log('[WebRTC] Setting remote description');
    await this.pc!.setRemoteDescription(answer);
  }

  private setupEventListeners() {
    this.pc?.addEventListener('connectionstatechange', () => {
      const state = this.pc?.connectionState as ConnectionStatus;
      console.log('[WebRTC] Connection state change:', state);
      this.emit('connectionstatechange', state);
    });
  }

  private async sendSDPMessage(sdp: string, ephemeralKey: string) {
    const baseUrl = "https://api.openai.com/v1/realtime";
    const model = "gpt-4o-realtime-preview-2024-12-17";
    
    return axios.post(`${baseUrl}?model=${model}`, sdp, {
      headers: {
        Authorization: `Bearer ${ephemeralKey}`,
        "Content-Type": "application/sdp"
      }
    });
  }

  // Event emitter pattern implementation
  private listeners: Record<string, Function[]> = {};

  on(event: string, callback: Function) {
    if (!this.listeners[event]) this.listeners[event] = [];
    this.listeners[event].push(callback);
    return () => this.off(event, callback);
  }

  off(event: string, callback: Function) {
    this.listeners[event] = this.listeners[event]?.filter(fn => fn !== callback);
  }

  private emit(event: string, ...args: any[]) {
    this.listeners[event]?.forEach(callback => callback(...args));
  }

  async disconnect() {
    if (this.pc) {
      this.pc.close();
      this.pc = null;
    }
    this.emit('disconnected');
  }

  sendSessionUpdate(update: SessionUpdate) {
    console.log('[WebRTC] Sending session update:', JSON.stringify(update, null, 2));
    const event = {
      type: 'session.update',
      session: update.session
    };
    
    console.groupCollapsed('[WebRTC] Sending session update');
    console.debug('Full update object:', update);
    console.debug('Stringified payload:', JSON.stringify(event));
    console.groupEnd();

    this.dc?.send(JSON.stringify(event));
  }

} 